import React from 'react';

const PoliticaDevolucion = () => {
    return (
        <div className="politica-privacidad">
            <h1>Política de devolución</h1>
            <p>La inscripción del curso conlleva aceptar las siguientes condiciones:</p>
            <p>En el caso de contratación del curso de lectura labial, y debido a la propiedad intelectual de estos y su carácter digital, <strong>no se ofrecerán devoluciones ni reembolsos de este tipo de productos</strong>. Dado que el mismo conllevan la descarga o el suministro de grabaciones y materiales, no será admisible el desistimiento si el usuario hubiera accedido a los materiales o se los hubiera descargado, ya que se entiende completamente prestado el servicio y consumado el contrato. Una vez cancelada la operación evidentemente se perderá el acceso al contenido del curso.</p>
        </div>
    );
}
 
export default PoliticaDevolucion;