import React, { useContext, useEffect, useState } from 'react';
import { Fragment } from 'react';
import FaqContext from '../../context/faqContext';
import styled from 'styled-components';
import { useLocation } from 'react-router';



const FaqContainer = styled.div`   
padding:2rem;
height:1rem;
overflow: hidden;
cursor:pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
&.open {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


h2 {
    margin-bottom:2rem;
    font-size:25px;

    @media(max-width:414px) {
        font-size:15px;
    }
}

p {
    font-size:18px;
    background:rgba(255,255,255,0.7);
    padding:1rem;
    border-radius:10px;

    @media(max-width:414px) {
        font-size:13px;
    }
}

`

const FAQ = () => {

    const [altura, setAltura] = useState("1rem");


    const faqContext = useContext(FaqContext);
    const { getFaqCursos, state } = faqContext;
    const location = useLocation();

    const fondoTextura = 'images/texture.jpg';

    useEffect(async () => {
        await getFaqCursos();

    }, [location]);

    console.log(state);

    const handleClick = (e) => {
        if (e.target.parentElement.classList.contains('open')) {
            e.target.parentElement.classList.remove('open');
            e.target.parentElement.style.height = '1rem';
        } else {
            e.target.parentElement.classList.add('open');
            e.target.parentElement.style.height = `${e.target.nextElementSibling.offsetHeight+100}px`; 
        }
    }

    return (
        <div className="faq" style={{ backgroundImage: `url(${fondoTextura})` }}>
            <h3>Preguntas más frecuentes</h3>
            <div className="wrapper-white">
                {state.preguntas ? state.preguntas.map((preguntas, i) => {
                    return (
                        <FaqContainer className="faq-container">
                            <h2 onClick={handleClick}>{i+1} - {preguntas.pregunta}</h2>
                            <p>{preguntas.respuesta}</p>
                        </FaqContainer>
                    )
                }) : "Estamos cargando"}
            </div>
        </div>

    );
}

export default FAQ;