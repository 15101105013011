import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { NavLink, Redirect, useHistory, useLocation } from 'react-router-dom';
import RegistroContext from '../../context/registroContext';
import './card.scss';
import Ad from '../login/Ad';
import { Fragment } from 'react';
import axios from 'axios';


const stripePromise = loadStripe('pk_live_51IACPNA97eoLvTyBdxrYLxmGkS8T0tS5d32oS92aJXMbkdNZmwG0nbU9g0sFVHlxMiwCs6LiMZzdtAqIkPxGoqMf00EQUJe1jV');
const Card = () => {

    const location = useLocation();
    const registroContext = useContext(RegistroContext);
    const history = useHistory();

    const { registrarUsuario, registroState } = registroContext;

    const [usuario, addUser] = useState({
        name: "",
        surname: "",
        email: "",
        pass: "",
    });

    const CheckOutForm = () => {
        const stripe = useStripe();
        const elements = useElements();

        useEffect(() => {
            const { state } = location;
            if (usuario.name === '') {
                addUser({
                    email: state.email,
                    name: state.name,
                    surname: state.surname,
                    pass: state.pass
                })
            }
        }, [location])
        const handleSubmit = async (e) => {
            e.preventDefault();
            let clientSecret;
            const cardElement = elements.getElement(CardElement);
            console.log(usuario.email);
            try {
                clientSecret = await axios.get('https://infinite-inlet-80418.herokuapp.com/api/new/secret');
                console.log(clientSecret);
            } catch (error) {
                console.log(error);
            }

            try {
                const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret.data.client_secret, {
                    payment_method: {
                        type: 'card',
                        card: cardElement,
                        billing_details: {
                            name: usuario.email,
                        },
                    },
                });

                if (error) {
                    history.push('/error-pago');
                } else if (paymentIntent) {
                    if (paymentIntent.status === 'succeeded') {
                        history.push('/pasarela');
                        proceedCheckout();
                    } else {
                        history.push('/error-pago');
                    }
                }
            }

            catch (error) {
                console.log(error);
            }
        }

        const proceedCheckout = async() => {
            try {
                const userCheck = await axios.post('https://infinite-inlet-80418.herokuapp.com/api/new/checkout', { nombre: usuario.name, apellidos: usuario.surname, email: usuario.email, password: usuario.pass });
                if (userCheck.status === 'OK') {
                    history.push('/miscursos');
                }
            } catch(error) {
                console.log(error);
            }
        }

        return (
            <Fragment>
                <Ad
                    text={['El sistema de pago cuenta con la garantía de Stripe que es utilizado en grandes e-commerce como Amazon. Ten la seguridad de que tus datos serán correctamente protegidos en todo momento. Stripe gestiona la transacción de manera rápida y segura. Si necesitas más información sobre la política de devolución puedes hacer click en este ', <NavLink to="/politica-devolucion">enlace</NavLink>]}
                />
                <div className="wrapper-pay">
                    <h2>Tus datos de pago</h2>
                    <form className="form-group" onSubmit={handleSubmit}>
                        <input type="text" className="form-control" placeholder="Nombre del titular" />
                        <CardElement className="form-control" />
                        <input className="btn btn-success mt-2" type="submit" value="Suscribirse" />
                    </form>
                </div>
            </Fragment>
        )
    }

    return (
        <Elements stripe={stripePromise}>
            <CheckOutForm />
        </Elements>
    );
}

export default Card;