import React, { useEffect, useReducer, useHistory, useContext } from 'react';
import FaqContext from './faqContext';
import FaqReducer from './faqReducer';

import {FAQ_CURSO} from '../types';
import { Redirect } from 'react-router';

const FaqState = props => {
    const initialState = {
        preguntas: null
    }
    const [ state, dispatch ] = useReducer(FaqReducer, initialState);

    // Funciones

    const getFaqCursos = () => {

        const listadoPreguntas = [{pregunta: "¿Cuánto tiempo me llevará completar el curso?", respuesta:"En el curso vas a tu propio ritmo. Puedes tomar el tiempo que necesites en cada lección.Tendrás acceso al curso durante 10 meses. En estos 10 meses tendrás el tiempo suficiente para aprender con calma de todas las lecciones, pero si tras estos 10 meses necesitases más tiempo tendrías que renovar tu cuenta."}, {pregunta:"¿Qué tecnología necesito?", respuesta:"El curso está diseñado de manera sencilla. Tan solo necesitas un ordenador, una Tablet o móvil con acceso a internet."}, {pregunta:"Ya hice lectura labial antes, ¿Se necesita ser completamente principiante?", respuesta:"Este curso es perfecto para principiantes, pero también es bueno para todos aquellos que quieran avanzar en la lectura labial, que quieran refrescar sus conocimientos, mejorar sus habilidades o aprender algo nuevo.Mi opinión es que, cuanto más practiques la lectura labial más fácil te resultará. Este curso está diseñado para que ganes confianza y te sientas más cómodo mejorando tus habilidades labio-lectoras.No importa si tienes experiencia o no, aprenderás nuevas herramientas y trucos al igual que ganarás mucho en la práctica."}, {pregunta:"¿Cómo puedo resolver todas las dudas que tenga?", respuesta:"Si tienes alguna duda sobre el curso o cualquiera de los servicios que ofrezco, por favor, no dudes en ponerte en contacto a través de marta@leemislabios.com"}, {pregunta:"¿Qué pasa si cambio de idea y quiero devolver el curso?", respuesta:"Te ofrezco que veas una lección gratis antes de comprar el curso. Una vez comprado el curso no se te devolverá la cantidad asignada."}, {pregunta:"El curso tiene un coste de 195 € ", respuesta:"El curso tiene un coste de 195 €. Puedes pagarlo con tarjeta de manera completamente segura a través de la plataforma. El pago esta gestionado por Stripe que garantiza el pago seguro"}]

        dispatch({
            type:FAQ_CURSO,
            payload: listadoPreguntas
        })

    }


    return (
        <FaqContext.Provider
            value={{
                state,
                getFaqCursos
            }}
        >
            {props.children}
        </FaqContext.Provider>
    )
}


export default FaqState;
