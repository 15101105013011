import React, { useState, useContext, useEffect, Fragment } from 'react';
import AlertContext from '../../context/alertContext';
import axios from 'axios';
import './registro.scss';
import {useHistory} from 'react-router-dom';
import RegistroContext from '../../context/registroContext';
import Cargando from '../Cargando/Cargando';
import Ad from './Ad';

const Login = () => {

    const registroContext = useContext(RegistroContext);
    const {recargarLogin, state} = registroContext;
    const alertContext = useContext(AlertContext);
    const history = useHistory();

    const {alerta, mostrarAlerta, ocultarAlerta} = alertContext;

    const [usuario, agregarUsuario] = useState({
        name: "",
        surname: "",
        email: "",
        pass: "",
        passConfirm:"",
        cargando:false
    });

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    

    const updateState = (e) => {    
        agregarUsuario({
            ...usuario,
            [e.target.name] : e.target.value
        });

    }
    //extraemos los valores para resetar el formulario

    const {name, surname, email, pass, passConfirm, card} = usuario;

    const handleSubmit = async (e) => {
        e.preventDefault();      
        agregarUsuario({
            ...usuario,
            cargando:true,
        })
        const valEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valPass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        if(name.trim() == "") {
            mostrarAlerta('El campo nombre es obligatorio');
            agregarUsuario({
                ...usuario,
                cargando:false,
            })
            return;
        }
        if(surname.trim() == "") {
            mostrarAlerta('El campo apellido es obligatorio');   
            agregarUsuario({
                ...usuario,
                cargando:false,
            }) 
            return;
        }  if(valEmail.test(email) == false) {
            mostrarAlerta('El email no es válido');
            agregarUsuario({
                ...usuario,
                cargando:false,
            })
            return;
        } if(valPass.test(pass) == false) {
            mostrarAlerta('La contraseña no es lo suficientemente segura');
            agregarUsuario({
                ...usuario,
                cargando:false,
            })
            return;
        } else if(pass != passConfirm) {
            mostrarAlerta('Las contraseñas no coinciden');
            agregarUsuario({
                ...usuario,
                cargando:false,
            })
            return;
        } else {                   
            try {
                agregarUsuario({
                    ...usuario,
                    cargando:false,
                })

                history.push({
                    pathname: '/checkout',
                    state: usuario
                });
            }
            catch(error) {
                return console.log(error);
            }
        }
    }

    return  (  
        <Fragment>
            <h1>Regístrate</h1>
            <Ad
                text="Registrándote con este formulario podrás acceder a todos los cursos y material disponible. Rellena este formulario con tus datos personales y una contraseña personal. El siguiente paso será introducir los datos de tu tarjeta y ya tendrás acceso automático a todo el contenido."
            />
            {usuario.cargando ? <Cargando/>:<div className="formularioUsuario">
                <div className="wrapper-error">
                    {alerta != '' ? <p className={alerta.clase}>{alerta.msg}</p> : null}
                </div>
                <h2>Regístrate en LeeMisLabios</h2>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label name="name">Nombre</label>
                    <input type="text" 
                        name="name" 
                        className="form-control" 
                        placeholder="Introduce tu nombre"
                        onInput= {updateState}
                        value = {name}
                        />
                </div>
                <div className="form-group">
                    <label name="surname">Apellido</label>
                    <input type="text" 
                        name="surname" 
                        className="form-control" 
                        placeholder="Introduce tu apellido"
                        onInput = {updateState}
                        value = {surname}
                        />
                {alerta.tipo == 'APELLIDO' ? <div><p>{alerta.msg}</p></div> : ""}
                </div>
                <div className="form-group">
                    <label name="email">Email</label>
                    <input 
                        type="text" 
                        name="email" 
                        className="form-control" 
                        placeholder="Introduce tu usuario"
                        onInput = {updateState}
                        value = {email}
                        />
                {alerta.tipo == 'EMAIL' ? <div><p>{alerta.msg}</p></div> : ""}
                </div>
                <div className="form-group">
                    <label name="pass">Contraseña</label>
                    <input 
                        type="password" 
                        name="pass" 
                        className="form-control" 
                        placeholder="Introduce tu contraseña"
                        onInput = {updateState}
                        value = {pass}
                        />
                {alerta.tipo == 'PASS' ? <div><p>{alerta.msg}</p></div> : ""}
                </div>
                <div className="form-group">
                    <label name="pass">Contraseña</label>
                    <input 
                        type="password" 
                        name="passConfirm" 
                        className="form-control" 
                        placeholder="Repite tu contraseña"
                        onInput= {updateState}
                        value = {passConfirm}
                        />
                {alerta.tipo == 'PASSCONFIRM' ? <div><p>{alerta.msg}</p></div> : ""}
                </div>
                <input className='btn btn-info' type="submit" value="Siguiente"/>
                </form>
            </div>}

            
        </Fragment>
    )

}

export default Login;

