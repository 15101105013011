import React, { useEffect, useReducer, useHistory, useContext } from 'react';
import registroContext from './registroContext';
import axios from 'axios';
import Swal from 'sweetalert2';

import { REGISTRO_EXITO, REGISTRO_FALLIDO, RELOGIN, LOGIN, SESION, USUARIO, CLOSE } from '../types';

const RegistroReducer = (state, action) => {
    switch (action.type) {
        case REGISTRO_EXITO:
            localStorage.setItem('token', action.payload.token);
            Swal.fire({
                icon: 'success',
                title: `<p>${action.payload.message}</p><p>Ve a la sección de cursos y empieza tu entrenamiento</p>`,
                showConfirmButton: false,
                timer: 2200
            });

            return {
                usuario: action.payload.usuario,
                mensaje: action.payload.message,
                token: action.payload.token,
                autenticado: true,
                cargando: false
            }

        case LOGIN:
            localStorage.setItem('token', action.payload.token);
            return {
                nombre: action.payload.nombre,
                apellidos: action.payload.apellidos,
                token: action.payload.token,
                autenticado: true,
                cargando: false
            }


        case RELOGIN:
            return {
                ...state,
                nombre: action.payload.nombre,
                apellidos: action.payload.apellidos,
                token: action.payload.token,
                autenticado: true,
                cargando: false
            }

        case REGISTRO_FALLIDO:

            return {
                mensaje: action.payload.error
            }

        case SESION:
            return {
                ...state,
                cargando: false,
                autenticado: false
            }

        default:
            return state;
    }
}

const RegistroState = props => {
    const initialState = {
        token: null,
        usuario: null,
        mensaje: null,
        autenticado: false,
        cargando: true
    }
    const [state, dispatch] = useReducer(RegistroReducer, initialState);

    // Funciones
    const registrarUsuario = async (data) => {
        try {
            const respuesta = await axios.post('https://infinite-inlet-80418.herokuapp.com/api/new/checkout', data);
            dispatch({
                type: REGISTRO_EXITO,
                payload: respuesta.data
            });
        }

        catch (error) {
            dispatch({
                type: SESION,
                payload: error
            });
        }
    }

    const recargarLogin = async () => {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['authorization'] = token;

        try {
            const respuesta = await axios.get('https://infinite-inlet-80418.herokuapp.com/api/recarga');
            console.log(respuesta);
            dispatch({
                type: RELOGIN,
                payload: respuesta.data
            })

        }

        catch (error) {
            console.log(error);
            dispatch({
                type:SESION,
                payload: false
            })
        }

    }

    const doLogin = async (data) => {
        try {
            console.log("enta")
            const usuarioLogeago = await axios.post('https://infinite-inlet-80418.herokuapp.com/api/login/log', data);
            console.log(usuarioLogeago)
            if (usuarioLogeago.data.error) {
                dispatch({
                    type: SESION,
                    payload: usuarioLogeago.data.error
                })
            } else {
                dispatch({
                    type: LOGIN,
                    payload: usuarioLogeago.data
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const closeSesion = () => {

        dispatch({
            type: SESION,
            payload: "Se ha cerrado la sesión por parte del usuario"
        })

    }

    return (
        <registroContext.Provider
            value={{
                state: state,
                registrarUsuario,
                recargarLogin,
                doLogin,
                closeSesion
            }}
        >
            {props.children}
        </registroContext.Provider>
    )
}


export default RegistroState;

