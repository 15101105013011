import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router';
import RegistroContext from '../context/registroContext';

const Pasarela = () => {

    const registroContext = useContext(RegistroContext);
    const {state} = registroContext;
    const history = useHistory();

    useEffect(() => {
        if(state.autenticado) {
            history.push("/miscursos");
        }
    }, [state.autenticado]);

    return (<h2><i className="fas fa-loading"></i>Procesando petición</h2>  );
}
 
export default Pasarela;