import React, {useState} from 'react';
import './hero.scss';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';

const Button = styled.button`
    background:#00B1AD;

    i {
        color:white;
    }
`

const Hero = ({image}) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };   
    return (  
            <Carousel className="wrapper-hero" 
                activeIndex={index} 
                onSelect={handleSelect} 
                interval={8000} 
                prevIcon={<span className="icon-arrow_left"></span>}
                nextIcon={<span className="icon-arrow_right"></span>}
                >
                <Carousel.Item>
                    <div className="text-hero">
                        <h2>Te ayudo a comunicarte con confianza. Tu deficiencia auditiva <span className="destacado">no debería limitarte</span></h2>
                        <p>Mi especialidad es la rehabilitación auditiva y la lectura labial. Te ayudo a incrementar tu resto auditivo, a comprender mejor el habla y enseñarte las mejores formas de comunicación adaptadas a cada momento del proceso.</p>
                        <Button><i className="icon-profile mr-2"></i>Ver mi perfil</Button>
                    </div>
                     <img  width="100%" src={image[0]}/>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="text-hero">
                        <h2><span className="destacado">LEE MIS LABIOS:</span> EL PROGRAMA DE LECTURAL LABIAL QUE TE AYUDARÁ EN LA COMUNICACIÓN DIARIA</h2>
                        <p>Si tienes problemas de audición y quieres mejorar tu forma de comunicarte con los demás, Lee mis labios te ayudará a aprender lectura labial y mejorar tu comunicación diaria.</p>
                        <Button><i className="icon-profile mr-2"></i>¡Quiero saber más!</Button>
                    </div>
                     <img  width="100%" src={image[1]}/>
                </Carousel.Item>
            </Carousel>
    );
}
 
export default Hero;