import React, { Fragment, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const CursoItem = ({ titulo, leccion, nivel, imagenes, explicacion }) => {
    return (
        <Fragment>
            <div className="wrapper-left">
                <h4>{titulo}</h4>
                <div>
                    <ol>
                        {leccion.map(element => {
                            return <li>{element}</li>
                        })}
                    </ol>
                </div>
            </div>
        </Fragment>
    );
}

export default CursoItem;