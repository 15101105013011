import './App.scss';
import './fuente-iconica.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Registro from './components/login/Registro';
import Login from './components/login/Login';
import Home from './components/Home';
import CookieConsent from 'react-cookie-consent';
import Beneficios from './components/Beneficios/Beneficios';
import Pasarela from './components/Pasarela';
import Error404 from './components/Error404';
import Card from './components/card/Card';
import AlertState from './context/alertState';
import RegistroState from './context/registroState';
import CursoState from './context/cursoState';
import Cursos from './components/Cursos/Cursos';
import CursosPage from './components/Cursos/CursosPage';
import RutaPrivada from './components/RutaPrivada';
import SobreMi from './components/sobre-mi/SobreMi';
import Servicios from './components/Servicios';
import FaqState from './context/faqState';
import BlockContact from './components/Contacto/BlockContact';
import PoliticaPrivacidad from './components/Politicas/PolticaPrivacidad';
import PoliticaDevolucion from './components/Politicas/PoliticaDevolucion';
import PoliticaCookies from './components/Politicas/PoliticaCookies';
import Error from './components/Error';
import ErrorPago from './components/error/ErrorPago';

function App() {
  const titulo = "Marta Olmos";
  const fecha = new Date().getFullYear();
  return (
    <div className="App">
      <Router>
        <AlertState>
          <RegistroState>
            <FaqState>
            <Header
              titulo={titulo}
            />
            <div className="container">
              <CursoState>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/registro" component={Registro} />
                  <Route path="/checkout" component={Card} />
                  <Route path='/login' component={Login} />
                  <Route path='/404' component={Error404} />
                  <Route path='/pasarela' component={Pasarela} />
                  <Route path='/error-pago' component={ErrorPago} />
                  <Route path='/beneficios' component={Beneficios} />
                  <Route path="/leemislabios" component={Cursos} />
                  <Route path="/sobremi" component={SobreMi} />
                  <Route path="/servicios" component={Servicios} />
                  <Route exact path="/contacto" component={BlockContact}/>
                  <Route path="/politica-privacidad" component={PoliticaPrivacidad}/>
                  <Route path="/politica-devolucion" component={PoliticaDevolucion}/>
                  <Route path="/politica-cookies" component={PoliticaCookies}/>
                  <RutaPrivada exact path="/miscursos" component={CursosPage} />
                </Switch>
              </CursoState>
            </div>
            </FaqState>
          </RegistroState>
        </AlertState>
        <Footer
          fecha={fecha}
        />
        <CookieConsent
          buttonText="Acepto"
          buttonStyle={{backgroundColor:"#00B1AD", color:"#FFFFFF"}}
          style={{backgroundColor:"#0A2239", color:"#FFFFFF", padding:"1rem"}}
          expires={150}
        >Esta página utiliza cookies para mejorar tu experiencia de usuario. Si continuas navegando aceptas el uso de cookies, de lo contrario puedes abandonar la página.</CookieConsent>
      </Router>
    </div>
  )
}

export default App;
