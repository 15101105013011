import React from 'react';
import './blocks.scss';

const Blocks = ({imagen, texto, titulo}) => {
    return ( 
        <div className="wrapper-blocks">
            <img width="500" src={imagen}/>
            <div className="text-block">
                <h2>{titulo}</h2>
                <p>{texto}</p>
            </div>
        </div>
     );
}
 
export default Blocks;