export const NOMBRE = 'NOMBRE';
export const APELLIDO = 'APELLIDO';
export const PASS = 'PASS';
export const PASS_INCORRECT = 'PASS_INCORRECT';
export const PASSCONFIRM = 'PASSCONFIRM';
export const EMAIL = 'EMAIL';
export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';
export const USUARIO = 'USUARIO';
export const REGISTRO_EXITO = 'REGISTRO_EXITO';
export const REGISTRO_FALLIDO = 'REGISTRO_FALLIDO';
export const SESION = 'SESION';
export const RELOGIN = 'RELOGIN';
export const LOGIN = 'LOGIN';
export const PAY = 'PAY';
export const CURSO = 'CURSO';
export const SELECTED = 'SELECTED';
export const FAQ_CURSO = 'FAQ_CURSO';
export const CARGANDO = 'CARGANDO';
export const CLOSE = 'CLOSE';
export const SAVEBLOB = 'SAVEBLOB';
export const RESETBLOB = 'RESETBLOB';