import React, { Fragment, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import Text from '../home/Text';
import './beneficios.scss';
import RegistroContext from '../../context/registroContext';

const Beneficios = () => {

    const registroContext = useContext(RegistroContext);
    const {recargarLogin, state} = registroContext;


    const Imagen = styled.img`
        position:relative;
        left:-50px;
    `

    const listado = [
        'Recuperases la ilusión por quedar, porque sabes que no te vas a perder en la conversación.',
        'Dejases de tener miedo a relacionarte y sintieses que por fin sigues el hilo del mensaje.',
        'Dejases de ponerte nervioso cada vez que alguien te pregunta, porque ahora sí tienes una respuesta que darles.',
        'No te afectase el miedo a confundirte, porque ahora tienes las herramientas para que eso no sea un problema'
    ]

    return (    
        <Fragment>
            <div className="circular-wrapper">
                <div className="wrapper-content">
                    <h2>¿Por qué aprender lectura labial?</h2>
                    <p>Como logopeda y experta en audición y profesional durante los últimos 8 años en el aprendizaje e impartición de la lectura labial, he creado el programa “Aprende lectura labial” para ayudarte a tener una comunicación eficaz. </p>
                    <p>Cada día, muchos de mis alumnos se sienten inseguros, desplazados, desmotivados y con miedo a reunirse con amigos, familiares o compañeros de trabajo y que no se enteren de lo que dicen.</p>
                    <p>Cada vez salen menos y se aíslan más por temor a quedar en ridículo y el miedo a qué pensarán de ellos.</p>
                    <p>Quizás te sientas identificado con estos sentimientos. </p>
                    <p>¿Tú también lo pasas mal cada vez que sales?</p>
                    <p>¿Te sientes mal cuando te confundes y se ríen? ¿Piensas que no te comprenden?</p>
                    <p>Sin embargo, hay personas que se comunican sin ningún tipo de problemas aun no escuchando lo que les dicen.</p>
                    <p>¿Quieres ser como ellos?</p>
                    <p>Ahora, imagínate… Cómo cambiaría tu vida si después de este curso…</p>
                </div>
                <Text
                titular = {["Cómo", <span class="destacado">cambiaría tu vida</span>, "si después de este curso…"]}
                subtitulo = ''
                lista={listado}
                icono ="fas fa-check"
                /> 
            </div> 
        </Fragment> 
    );
}
 
export default Beneficios;