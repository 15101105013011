import React from 'react';
import styled from 'styled-components';

const Formulario = styled.form`
    padding:3rem;
    border-radius:20px;
    background:#0A2239;
    min-width:400px;
    text-align:center;

    h4 {
        color:white;
        font-size:40px;
        margin: 2rem 0;
    }

    #ycbmiframemartaolmoslogopedia {
        width:100%;
        min-height:600px;
        border:0px;
        background-color:transparent;
    }
    

    .form-group {
        margin:1rem 0;
    }

    button {
        background:#00B1AD;
    }
`

const Form = () => {


    return (
        <Formulario>
            <h4>Haz tu reserva</h4>
            <iframe src="https://martaolmoslogopedia.youcanbook.me/?noframe=true&skipHeaderFooter=true" id="ycbmiframemartaolmoslogopedia" frameborder="0" allowtransparency="true"></iframe>
        </Formulario>
    );
}
 
export default Form;