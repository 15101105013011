import React, { useReducer } from 'react';
import alertaContext from './alertContext';

import { MOSTRAR_ALERTA ,OCULTAR_ALERTA, USUARIO} from '../types';

const alertaReducer = (state, action) => {
    switch(action.type) {
        case MOSTRAR_ALERTA:
            return {
                alerta: action.payload
            }
        case OCULTAR_ALERTA:
            return {
                alerta: ""
            }

        case USUARIO: {
            return {
                alerta:action.payload
            }
        }    
        default:
            return state;
    }
}

const AlertaState = props => {
    const initialState = {
        alerta: ''
    }

    const [ state, dispatch ] = useReducer(alertaReducer, initialState);

    // Funciones
    const mostrarAlerta = (msg) => {
        dispatch({
            type:  MOSTRAR_ALERTA,
            payload: {
                msg,
                clase:'msg-error'
            }
        });
        setTimeout(ocultarAlerta, 3000);
    }

    const ocultarAlerta = () => {
        dispatch({
            type: OCULTAR_ALERTA
        })
    }
    return (
        <alertaContext.Provider
            value={{
                alerta: state.alerta,
                mostrarAlerta,
                ocultarAlerta
            }}
        > 
            {props.children}
        </alertaContext.Provider>
    )
}

export default AlertaState;