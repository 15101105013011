import React, { Component, useContext, useEffect, useState } from 'react';
import './header.scss';
import { Link, NavLink, useHistory } from 'react-router-dom';
import RegistroContext from '../../context/registroContext';
import ReactGA from 'react-ga';

const Header = ({ titulo }) => {
    const registroContext = useContext(RegistroContext);
    const { state, closeSesion } = registroContext;

    const history = useHistory();

    const [menu, setMenu] = useState(false);


    const handleClick = () => {
        localStorage.removeItem('token');
        history.push('/');
        closeSesion();
    }

    const handleMenu = () => {
        setMenu(!menu);
    }

    const handleToggle = () => {
        setMenu(false);
    }

    useEffect(() => {
        ReactGA.initialize('G-KMRNQL5CDG');
        let getLink = document.querySelectorAll('ul a');
        console.log(getLink);

        for (let link of getLink) {
            link.addEventListener('click', () => {
                setMenu(false);
            })
        }

    }, [])


    return (
        <nav>
            {state.autenticado === true ? <div className="user-wrapper"><div className="user-info"><span>Bienvenido <i className="icon-user"></i> {state.nombre} {state.apellidos}</span></div><a onClick={handleClick}>Cerrar Sesión</a></div> : null}
            <div className="menuPrincipal">
                <div onClick={handleMenu} className="burger-menu"><i className="icon-ion-android-menu"></i></div>
                <NavLink className="logo" to="/">
                    <h1>{titulo}</h1>
                    <p className="small-destacado">Logopeda especializada en audición</p>
                </NavLink>
                <ul className={!menu ? '' : 'open'}>
                    <li onClick={handleToggle} className="icon-times"></li>
                    <li>
                        <NavLink to="/servicios" activeClassName="active">
                            Servicios
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/leemislabios" activeClassName="active">
                            LeeMisLabios
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/sobremi" activeClassName="active">
                            Sobre mi
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contacto" activeClassName="active">
                            Contacto
                        </NavLink>
                    </li>
                    {!state.autenticado ?
                        <li>
                            <NavLink to="/login" activeClassName="active">
                                Accede
                        </NavLink>
                        </li> :
                        <li>
                            <NavLink to="/miscursos" className="active">
                                Mi Curso
                            </NavLink>
                        </li>
                    }
                </ul>
            </div>
        </nav>
    )
}

export default Header;