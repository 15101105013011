import React, { useState, useEffect, useContext } from 'react';
import { Fragment } from 'react';
import CursoContext from '../../context/cursoContext';

const Video = ({ url, subtitulo, descripcion, leccion, titulo }) => {
    const cursoContext = useContext(CursoContext);
    const { estado, cargandoCurso, saveBlob, resetBlob } = cursoContext;
    const [src, setSrc] = useState([]);
    useEffect(async () => {
        resetBlob();
        var xhr = new XMLHttpRequest();

        xhr.open('GET', url);
        xhr.responseType = 'arraybuffer'
        xhr.onload = async (e) => {
            var blob = await new Blob([xhr.response])
            var urln = await URL.createObjectURL(blob);
            setTimeout(() => {cargandoCurso();}, 1000);     
            saveBlob(leccion, urln);
            
        }
        xhr.send();

    }, [])

    return (
        <Fragment>
            <div className={`wrapper-video ${estado.cursoSeleccionado == leccion ? `open` : `d-none`}`}>
                <div className="descripcion">
                    <h4>{leccion}</h4>
                    <p>{descripcion}</p>
                </div>
                {leccion === 'Antes de empezar...'? 
                    <div className="documentos">
                        <h5>Esta lección tiene archivos descargables</h5>
                        <a href="docs/que-es-lectura-labial.pdf" download><i className="icon-file-pdf"></i>¿Qué es la lectura labial?</a>
                        <a href="docs/consejos-utiles-empezar.pdf" download><i className="icon-file-pdf"></i>Consejos útiles para empezar</a>
                    </div>         
                : null}
                {leccion === 'Saludos'? 
                    <div className="documentos">
                        <h5>Esta lección tiene archivos descargables</h5>
                        <a href="docs/comunicarte-ppa.pdf" download><i className="icon-file-pdf"></i>Cómo comunicarte con una persona con pérdida auditiva</a>
                        <a href="docs/comunicarte-pp.pdf" download><i className="icon-file-pdf"></i>Cómo comunicarte si tienes pérdida auditiva</a>
                    </div>         
                : null}
                {leccion === 'Familia'? 
                    <div className="documentos">
                        <h5>Esta lección tiene archivos descargables</h5>
                        <a href="docs/comunicacion-triangulo.pdf" download><i className="icon-file-pdf"></i>Triángulo comunicativo</a>
                    </div>         
                : null}
                {leccion === 'Ejercicio cognitivo'? 
                    <div className="documentos">
                        <p>*Ha llegado la hora de entrenar tu mente. En el siguiente PDF encontrarás unas frases en las que verás que le faltan letras a cada una de ellas. Lo que tienes que hacer es tratar de adivinar que letras son las que pueden encajar para construir la frase. En algunos huecos cabe una letra y en otros puede caber dos o tres letras. Descarga el PDF y dale al coco. Cuando creas haber resuelto todas las frases, mira el vídeo siguiente en el que te mostraré las respuestas con la lectura labial. Ánimo.</p>
                        <p>¿Para qué sirve este ejercicio?</p>
                        <p>Es importante que entrenes tu mente para conseguir esa agilidad mental. Cuando tengas conversaciones te darás cuenta de que no puedes captar todo lo que te dicen y tendrás huecos en blanco en esa conversación. Si entrenas tu mente te resultará más fácil acceder a esos huecos en blanco y rellenarlos con lo que podría encajar en cada momento.</p>
                        <p>Para hacerte una comparativa sería como si leyeras muy rápido un texto en el que no te detienes en cada palabra, pero sí vas cogiendo el significado de este a medida que pasas tu visión rápidamente.</p>
                        <p>Con la practica cada vez te resultará más sencillo rellenar esos huecos en blanco y tener una mayor agilidad mental.</p>
                        <h5>Esta lección tiene archivos descargables</h5>
                        <a href="docs/ejercicio-cognitivo.pdf" download><i className="icon-file-pdf"></i>Ejercicio cognitivo</a>
                    </div>         
                : null}
                {estado.blobReady ?
                    <video controls>
                        {estado.arrBlob.map(e => {
                            if(e.leccion == leccion) {
                                return  <source src={e.blob} type="video/mp4" />
                            }
                        })}
                       
                        <track src={subtitulo != '' ? subtitulo : ''} kind="subtitles" label="Activados" srclang="Español" default />
                    </video>
                    : <h1>Estamos preparando el video<i className="fas fa-circle-notch fa-spin"></i></h1>}
            </div>

        </Fragment>

    );
}

export default Video;