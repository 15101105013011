import React, { useEffect } from 'react';
import Form from '../home/Form';
import TextContact from './TextContact';
import '../../App.scss';
import { Fragment } from 'react';

const BlockContact = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const fondoSignos = '../images/fondo-signos.png';
    return (
        <Fragment>
            <div className="container-contact" style={{ backgroundImage: `url(${fondoSignos})` }}>
                <TextContact />
            </div>
        </Fragment>
    );
}

export default BlockContact;