import React, { useEffect } from 'react';
import { Fragment } from 'react';
import FloatBlock from './servicios/FloatBlock';

const Servicios = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <Fragment>
            <FloatBlock/>
        </Fragment>
    );
}
 
export default Servicios;