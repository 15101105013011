import React ,{useState} from 'react';
import Carousel from 'react-bootstrap/Carousel'
import styled from '@emotion/styled';
import './hero.scss';


const WrapperQuote = styled.div`
  max-width:750px;
  margin:auto;
  text-align:left;
  background:rgba(0,177,173,.3);
  padding:2rem;
  border-radius:10px;
  color:#0A2239;

  h4 {
    text-align:center;
  }
  
`


const Carrusel = ({cursos}) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };   

    return (    
    <section>
      <Carousel 
        activeIndex={index} 
        onSelect={handleSelect}
        prevIcon={<span className="icon-arrow_left"></span>}
        nextIcon={<span className="icon-arrow_right"></span>}
        >
          <Carousel.Item>
            <Carousel.Caption>
              <WrapperQuote>
                <h4>Milagros E.H</h4>
                <p>Al empezar el curso mi cambio ha sido espectacular. He pasado de no entender nada a poder hacer mi vida habitual sin problemas. Mi mayor temor era el pensar que fuera difícil, pero con Marta fue todo lo contrario. Fácil y ameno.</p>
                <p>Algo importantísimo gracias al curso ha sido poder volver a ir a los médicos yo sola y ser capaz de seguir una conversación con un grupo de personas. Lo mejor es que puedes seguir el ritmo que tú te marques y repetirlo tantas veces como tú lo necesites.</p>
                <p>Recomendaría este curso a todo el mundo. Te facilita el día a día y te hace sentir mucho más cómoda en cualquier conversación.</p>
              </WrapperQuote>
            </Carousel.Caption>
          </Carousel.Item>
          {!cursos ? 
                    <Carousel.Item>
                    <Carousel.Caption>
                      <WrapperQuote>
                        <h4>Isabel G.E.</h4>
                        <p>Con la logopedia aprendí junto a Marta a identificar sonidos que antes no percibía. También aprendí a identificar fonemas o letras que confundía con otras, debido a mis problemas de audición (...) Lectura labial y logopedia se complementan, no se excluyen, ambas son ayudas muy útiles para los hipoacústicos. </p>
                        <p>También en logopedia, Marta, muestra su profesionalidad y más aún su paciencia. Nunca se cansa de repetir palabras y aunque al final no las entiendas nunca te hace sentir mal. </p>
                        <p>Gracias a la logopedia puedo discriminar mejor algunas palabras y fonemas, y por tanto entiendo mejor que lo hacía antes.</p>
                      </WrapperQuote>
                    </Carousel.Caption>
                  </Carousel.Item>
        
          : null}

          <Carousel.Item>
            <Carousel.Caption>
              <WrapperQuote>
                <h4>Oscar L.L</h4>
                <p>Antes era incapaz de entender a nadie cuando me hablaban y ahora soy capaz de seguir las conversaciones incluso estando en grupo.</p>
                <p>Gracias al curso he podido ir a la tutoría de mi hijo y he podido entender con claridad lo que me decía su profesora. </p>
                <p>Ha supuesto un antes y un después a la hora de relacionarme con la gente, pero sobre todo destaco la seguridad con la que ahora me enfrento a ellas.</p>
              </WrapperQuote>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>  
      </section>
      );
}
 
export default Carrusel;