import React from 'react';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const WrapperFloat = styled.div`
    display:flex;
    border-left:20px solid #00B1AD;
    padding:2rem;

    @media(max-width:768px) {
        flex-direction:column-reverse;
        border:none;
        padding:1rem;
    }

    &:nth-child(odd) {
        border-right:20px solid #0A2239;
        flex-direction:row-reverse;
        border-left:none;

        @media(max-width:768px) {
            flex-direction:column-reverse;
            border:none;
        }
    }

    & > * {
        margin:2rem 1rem;
        width:48%;

        @media(max-width:768px) {
            width:80%;
            margin: 1rem auto;
        }
    }
    
    .text {
        align-self:center;

        button {
            margin:1rem 0;
        }
        h6 {
            font-weight:bolder;
            color:#0A2239;
        }

        ul {
            list-style:circle;
            li {
                margin:1rem;
                margin-left:2rem;
            }
        }

        a.link {
            display:block;
            background:#0A2239;
            width:150px; 
            padding:1rem 0;
            text-align:center;
            border-radius:20px;
            color:white;

            @media(max-width:414px) {
                margin:0 auto;
            }
        }
    }

    .imagen {
        overflow: hidden;
    }

    img {
        width:100%;
        position:relative;

        @media(max-width:414px) {
            width:90%;
        }
    }

`

const FloatBlock = () => {
    return (
        <Fragment>
            <WrapperFloat>
                <div className="text">
                    <h2>Servicio de rehabilitación auditiva</h2>
                    <h6>Enfoque adulto</h6>
                    <p>Si eres hipoacúsico, tienes implante coclear o alguna deficiencia auditiva, te ayudo a sacar el máximo provecho a tu resto auditivo.</p>
                    <p>A veces sólo con la prótesis no basta y necesitamos seguir entrenando nuestra capacidad auditiva para una mayor comprensión del habla.</p>
                    <p>Mediante las técnicas de detección, discriminación, identificación y reconocimiento, te ayudo a mejorar tu resto auditivo y así aumentar tu calidad de vida.</p>
                    <h6>Enfoque pedriático</h6>
                    <p>La pérdida de audición infantil tiene un impacto muy grande en su desarrollo, por eso es importante empezar cuanto antes en la intervención logopédica y sacar el máximo provecho de sus posibilidades auditivas.</p>
                    <p>El objetivo es conseguir la adquisición y desarrollo del lenguaje oral. Acercando la escucha activa al niño. Orientando y asesorando a los padres para ofrecerles un entorno auditivo de calidad.</p>
                    <p>La intervención logopédica se centrará en:</p>
                    <ul>
                        <li>Estimulación de la capacidad auditiva que los niños pueden obtener a través de sus prótesis auditivas (detección, discriminación, identificación, reconocimiento, comprensión).</li>
                        <li>Adquisición y desarrollo del lenguaje oral, tanto en compresión como en expresión (fonética-fonología, semántica, morfosintaxis y pragmática).</li>
                        <li>Estimulación de la competencia lectora (aprendizaje de la lecto-escritura y estimulación de los procesos lectores).</li>
                        <li>Información y formación a los padres, si fuera necesario, sobre sistemas de apoyo a la comunicación oral (Sistema Bimodal, Palabra Complementada…) para facilitar y enriquecer su comprensión y expresión en las interacciones comunicativas.</li>
                    </ul>
                    <NavLink className="link" to="/contacto">¿Empezamos?</NavLink>
                </div>
                <div className="imagen">
                    <img src="images/collage-sordos.jpg" alt="Rehabilitacion auditiva sordos" />
                </div>
            </WrapperFloat>
            <WrapperFloat>
                <div className="text">
                    <h2>Servicio de lectural labial</h2>
                    <p>Como primera opción te ofrezco el curso <NavLink to="leemislabios">“LeeMisLabios”</NavLink> en el que encontrarás toda la información sobre como aprender lectura labial.</p>
                    <p>Bien, si ya has realizado el curso y quieres seguir avanzando en esta área podrás contratar mis servicios de manera on-line, o presencial en caso de que seas de Madrid.</p>
                    <p>Trabajaremos más profundamente en los aspectos de la lectura labial y que puedas coger cada vez mayor fluidez lectora. </p>
                    <p>El servicio se adapta a cada persona y área de su vida. Tú decides en qué aspectos quieres avanzar más y yo te ayudo a conseguirlo.</p>
                    <NavLink className="link" to="/leemislabios">¿Empezamos?</NavLink>
                </div>
                <div className="imagen">
                    <img src="images/curso-lectura-labial.jpg" alt="Curso de lectura labial online" />
                </div>
            </WrapperFloat>
            <WrapperFloat>
                <div className="text">
                    <h2>Terapia Vocal</h2>
                    <p>Cuando tenemos una pérdida auditiva en algunas ocasiones nos puede afectar a nuestra voz. Al no tener una retroalimentación auditiva de calidad, se puede llegar a forzar la voz y perder el control de ésta resultando una voz irritante o muy aguda. </p>
                    <p>Si es tu caso y quieres mejorar el control de tu voz te ofrezco mis servicios para trabajar la modulación de la voz mejorando el ritmo y tono de la voz y conseguir una mayor fluidez verbal.</p>
                    <p>El objetivo será conseguir:</p>
                    <ul>
                        <li>Una función respiratoria correcta</li>
                        <li>Obtención de un registro vocal óptimo</li>
                        <li>Aprendizaje de armónicos vocales</li>
                        <li>Mejora de la articulación</li>
                    </ul>
                    <NavLink className="link" to="/contacto">¿Empezamos?</NavLink>
                </div>
                <div className="imagen">
                    <img src="images/terapia-vocal-logopeda.png" alt="Terapia Vocal Logopeda" />
                </div>
            </WrapperFloat>
            <WrapperFloat>
                <div className="text">
                    <h2>Peritaje logopédico</h2>
                    <p>Creación de informes especializados en audición y voz.</p>
                    <p>Como logopeda, dentro de un procedimiento judicial en curso, aportare a éste mis conocimientos fisioterápicos, científicos y prácticos, concluyendo sobre el caso planteado, de forma absoluta, probable o posible.</p>
                    <p>La logopedia actúa como colaborador siendo el objetivo de su intervención una prueba más que aportará al caso. Pueden ser requeridos por el juez o por una parte, es decir, por un abogado o por cualquier persona que lo necesite. </p>
                    <NavLink className="link" to="/contacto">¿Empezamos?</NavLink>
                </div>
                <div className="imagen">
                    <img src="images/peritaje-logopedico.jpg" alt="Peritaje logopédico" />
                </div>
            </WrapperFloat>
        </Fragment>

    );
}

export default FloatBlock;