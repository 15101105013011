import React from 'react';

const PoliticaCookies = () => {
    return (
        <div className="politica-privacidad">
            <h1>Política de Cookies</h1>
            <p>La presente política de cookies tiene por finalidad informarle de manera clara y precisa sobre las cookies que se utilizan en la página web de Marta Olmos https://martaolmoslogopeda.com/.</p>
            <h3>¿Qué son las cookies?</h3>
            <p>Una cookie es un pequeño fragmento de texto que los sitios web que visita envían al navegador y que permite que el sitio web recuerde información sobre su visita, como su idioma preferido y otras opciones, con el fin de facilitar su próxima visita y hacer que el sitio le resulte más útil. Las cookies desempeñan un papel muy importante y contribuyen a tener una mejor experiencia de navegación para el usuario.</p>
            <h3>Tipos de cookies</h3>
            <p>Según quién sea la entidad que gestione el dominio desde dónde se envían las cookies y se traten los datos que se obtengan, se pueden distinguir dos tipos: cookies propias y cookies de terceros.</p>
            <p>Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de cookies de sesión o cookies persistentes.</p>
            <p>Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los datos obtenidos: cookies técnicas, cookies de personalización, cookies de análisis, cookies publicitarias y cookies de publicidad comportamental.</p>
            <p>Para más información a este respecto puede consultar la Guía sobre el uso de las cookies de la Agencia Española de Protección de Datos.</p>
            <h3>Cookies utilizadas en la web</h3>
            <p>A continuación se identifican las cookies que están siendo utilizadas en este portal así como su tipología y función:</p>
            <p>La página web de Marta Olmos https://martaolmoslogopeda.com/ utiliza Google Analytics, un servicio de analítica web desarrollada por Google, que permite la medición y análisis de la navegación en las páginas web. En su navegador podrá observar cookies de este servicio. Según la tipología anterior se trata de cookies propias, de sesión y de análisis.</p>
            <p>A través de la analítica web se obtiene información relativa al número de usuarios que acceden a la web, el número de páginas vistas, la frecuencia y repetición de las visitas, su duración, el navegador utilizado, el operador que presta el servicio, el idioma, el terminal que utiliza y la ciudad a la que está asignada su dirección IP. Información que posibilita un mejor y más apropiado servicio por parte de este portal.</p>
            <p>Para garantizar el anonimato, Google convertirá su información en anónima truncando la dirección IP antes de almacenarla, de forma que Google Analytics no se usa para localizar o recabar información personal identificable de los visitantes del sitio. Google solo podrá enviar la información recabada por Google Analytics a terceros cuanto esté legalmente obligado a ello. Con arreglo a las condiciones de prestación del servicio de Google Analytics, Google no asociará su dirección IP a ningún otro dato conservado por Google.</p>
            <h3>Aceptación de la política de cookies</h3>
            <p>Pulsando el botón Entendido se asume que usted acepta el uso de cookies.</p>
            <h3>Cómo modificar la configuración de las cookies</h3>
            <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
        </div>
    );
}
 
export default PoliticaCookies;