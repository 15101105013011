import React, { Fragment, useContext, useState, useEffect } from 'react';
import { NavLink, Redirect, useHistory } from 'react-router-dom';
import Mensaje from '../Mensaje';
import AlertContext from '../../context/alertContext';
import RegistroContext from '../../context/registroContext';
import './registro.scss';
import Cargando from '../Cargando/Cargando';

const Login = (props) => {

    const alertContext = useContext(AlertContext);
    const registroContext = useContext(RegistroContext);
    const { mostrarAlerta, alerta } = alertContext;
    const { doLogin, state } = registroContext;

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0,0);
        if (state.autenticado) {
            history.push("/miscursos");
        }
    }, [state.autenticado]);

    const [login, updateLogin] = useState({
        email: "",
        password: "",
        cargando: false,
    })

    const updateState = (e) => {
        updateLogin({
            ...login,
            [e.target.name]: e.target.value,
        });

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        updateLogin({
            ...login,
            cargando: true,
        })
        await doLogin(login);

        if (state.mensaje) {
            mostrarAlerta(state.mensaje, 'USUARIO');
        }

        updateLogin({
            ...login,
            cargando: false,
        })
    }


    return (
        <Fragment>
            <h1>Accede a tu cuenta</h1>
            <Mensaje />
            {login.cargando ? <Cargando /> : <div className="container-login">
                <form onSubmit={handleSubmit}>
                    <h4>Accede a tu cuenta</h4>
                    {alerta.tipo == 'USUARIO' ? <div><p>{alerta.msg}</p></div> : ""}
                    <div className="form-group">
                        <label>Usuario(email)</label>
                        <input className="form-control"
                            type="email"
                            name="email"
                            placeholder="Tu email"
                            onInput={updateState}
                            value={login.email}
                        />
                        {alerta.tipo == 'EMAIL' ? <div><p>{alerta.msg}</p></div> : ""}
                    </div>
                    <div className="form-group">
                        <label>Contraseña</label>
                        <input className="form-control"
                            type="password"
                            name="password"
                            placeholder="Contraseña"
                            onInput={updateState}
                            value={login.password}
                        />
                        {alerta.tipo == 'PASS_INCORRECT' ? <div><p>{alerta.msg}</p></div> : ""}
                    </div>
                    <input type="submit" className="btn btn-success" value="Entrar" />
                </form>
                <div className="registro-container">
                    <NavLink to="/registro" activeClassName="active">
                        ¿Aún no tienes cuenta? Regístrate
                </NavLink>
                </div>
            </div>
            }


        </Fragment>

    );
}

export default Login;