import React, { Fragment, useContext } from 'react';
import RegistroContext from '../context/registroContext';

const Mensaje = () => {
    const registroContext = useContext(RegistroContext);
    const {state} = registroContext;
    return (
        <Fragment>
            {state.error ? <div className="wrapper-error"><h5 className="msg-error">{state.error}</h5></div> : null }
        </Fragment>
    );
}
 
export default Mensaje;