import React from 'react';
import styled from 'styled-components';
import '../../App.scss';
import {NavLink} from 'react-router-dom';

const Card = styled.div`
    padding:3rem;
    background:rgba(0,177,173, .3);
    text-align:center;
    width:45%;
    margin: 2rem;
    border-radius:17px;
    position:relative;
    min-height:400px;

    @media(max-width:768px) {
        width:90%;
        overflow:hidden;
        min-height:270px;
    }

    p {
        font-size:1.3rem;
    }

    &:nth-child(2), :nth-child(5) {
        background:#0A2239;
        
        h2, p {
            color:white;
        }
    }

    a {
        background:white;
        padding:0.5rem 1rem;
        color:#0A2239;
        border-radius:10px!important;
        position:absolute;
        bottom:10%;
        left:35%;
        font-weight:bolder;

        @media(max-width:414px) {
            position:static;
        }
    }

`

const Button = styled.a`
    background:white;
    padding:0.5rem 1rem;
    color:#0A2239;
    border-radius:10px!important;
    position:absolute;
    bottom:10%;
    left:35%;
    font-weight:bolder;

    i {
        color:#0A2239;
    }



`

const GiroCard = ({titulo, texto}) => {

    return (
        <Card>
            <h2>{titulo}</h2>
            <p>{texto}</p>
            <NavLink to="/servicios">Quiero saber más</NavLink>
        </Card>
    );
}
 
export default GiroCard;