import React, { useContext, useEffect } from 'react';
import {Route, Redirect} from 'react-router-dom';
import {Card} from './card/Card';

import RegistroContext from '../context/registroContext';

const RutaPrivada = ({component: Component, ...props}) => {
    
    const registroContext = useContext(RegistroContext);
    const {recargarLogin, state} = registroContext;

    useEffect(async() =>{
        await recargarLogin();
    },[])

    return (
        <Route {...props} render={props => state.autenticado != true ? (
            <Redirect to ='/login'/>
            ): (<Component {...props}/>
        )} />
    )
}
 
export default RutaPrivada;
