import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const BlockPrecio = styled.div`
    padding:3rem;
    max-width:1300px;
    background:#B2E7E6;
    text-align:center;
    position:relative;
    border-top:10px solid #0A2239;
    margin:auto;

    p {
        font-size:25px;
        line-height:30px;
        max-width:700px;
        margin:1rem auto;
        color:#0A2239;
    }

    h2 {
        font-size: 45px;
        margin-bottom:2rem;
    }

    span {
        font-size:28px;
    }

    button {
        margin-top:2rem;
        font-weight:bolder;
        background:#0A2239;
    }
`

const PrecioFinal = () => {
    return (
        <BlockPrecio>
            <h2>PRECIO FINAL</h2>
            <p>En total 27 vídeos y 5 bonus, que podrás <strong>conseguir todo por sólo 195€</strong>. No pierdas esta oportunidad.</p>
            <NavLink to="/registro"><button>SUSCRÍBETE</button></NavLink>
        </BlockPrecio>
    );
}
 
export default PrecioFinal;