import {FAQ_CURSO} from '../types';


const FaqReducer = (state, action) => {
    switch(action.type) {
        case FAQ_CURSO:
            return {
                preguntas:action.payload

            }

        default:
            return state;
    }
}

export default FaqReducer;