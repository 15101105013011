import {PAY, CURSO, SELECTED, CARGANDO, SAVEBLOB, RESETBLOB} from '../types';

const cursoReducer = (state, action) => {
    switch(action.type) {
        case PAY:
            return {
                ...state,
                cursos:action.payload,
                cursoCargado: true,
            } 

        case CURSO:
            return {
                ...state,
                cursos:action.payload,
                cursoCargado: true
            } 

        case SELECTED:
            return {
                ...state,
                cursoSeleccionado : action.payload
            } 

        case CARGANDO: 
            return {
                ...state,
                cargando:action.payload
            }

        case SAVEBLOB: 
            return {
                ...state,
                arrBlob: [...state.arrBlob, action.payload],
                blobReady:true
            }

        case RESETBLOB: 
            return {
                ...state,
                blobReady:false
            }
            
        default:
            return state;
    }
}

export default cursoReducer;