import React, { useContext, useEffect, useState } from 'react';
import CursoContext from '../../context/cursoContext';
import RegistroContext from '../../context/registroContext';
const ListaCurso = ({curso, leccion}) => {
const cursoContext = useContext(CursoContext);
const {selectedCurso} = cursoContext;

const registroContext = useContext(RegistroContext);

function handleToggle(e) {

  if(e.target.parentElement.classList.contains('open')) {
      e.target.parentElement.classList.remove('open');
      e.target.children[0].classList = 'icon-plus';
  } else {
    e.target.parentElement.classList.add('open');
    e.target.children[0].classList = 'icon-minus';
  }


}

function handleClick(e) {
  selectedCurso(e.target.getAttribute('data-text'))
  const getVideos = document.querySelectorAll('video');
  for(let video of getVideos) {
    video.pause();
  }
}


    return (
      <div className="card-listado">
        <h4 onClick={handleToggle}>{curso} <i className="icon-plus"></i></h4>
        <ul>
          {leccion.map((leccionI, i) => {
            return <li className="leccion" key={i} data-text={leccionI} onClick={handleClick}>{leccionI}<span data-text={leccionI} className="ml-3">Ver Lección</span></li>
          })}
        </ul>
      </div>
      );
}
 
export default ListaCurso;