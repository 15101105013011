import React from 'react';
import {NavLink} from 'react-router-dom';

const TextContact = () => {
    const logo = '../images/lee_iso.png';
    return (
        <div className="text-contact">
        <h2>¿Cómo puedo atenderte?</h2>
        <ul>
            <li><NavLink to="/"><i className="icon-at"></i>Terapia Online</NavLink></li>
            <li><NavLink to="/"><i className="icon-home"></i>Terapia a domicilio</NavLink></li>
            <li><NavLink to="/"><div className="wrapper-img"><img className="icon" src={logo}/></div>Curso de Lectura Labial</NavLink></li>
        </ul>
        {/* <h4 className="mt-5">¿Quieres una cita online gratis?</h4>
        <p>Rellena el formulario e indica la opción <strong>"Cita gratuita"</strong> en el menú desplegable. Indícame los detalles de tu consulta en el campo de texto y te contactaré a través del email que me proporciones para fijar el horario de tu cita online gratuita.</p> */}
        <div className="wrapper-contact">
            <h4>Medios de contacto</h4>
            <p><i className="icon-phone"></i>620 99 61 22</p>
            <p><i className="icon-envelope"></i><a href="mailto:leemislabiosinfo@gmail.com">leemislabiosinfo@gmail.com</a></p>
        </div>
    </div>
    );
}
 
export default TextContact;