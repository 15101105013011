import cursoReducer from './cursoReducer';
import CursoContext from './cursoContext';
import { CURSO, PAY, SELECTED, CARGANDO, SAVEBLOB, RESETBLOB } from '../types';
import axios from 'axios';

import React, { useReducer} from 'react';

const CursoState = props => {
    const initialState = {
        cursos:null,
        cursoCargado : false,
        cursoSeleccionado : "Introduccion",
        cargando:false,
        blobReady: false,
        arrBlob : [{leccion:"", blob:""}]
    }

    const [ estado, dispatch ] = useReducer(cursoReducer, initialState);

    const getAllCursos = async () => {

        try {
            const token = localStorage.getItem('token');
            axios.defaults.headers.common['authorization'] = token;
            const cursosGet = await axios.get('https://infinite-inlet-80418.herokuapp.com/api/cursos-pres');
            console.log(cursosGet);

            dispatch({
                type: CURSO,
                payload : cursosGet.data
            });

        }

        catch(error) {
            console.log(error);
        }
    }

    const getPayCursos = async () => {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['authorization'] = token;
        const cursos = await axios.get('https://infinite-inlet-80418.herokuapp.com/api/cursos');
        console.log(cursos);

        if(!cursos.error) {
            dispatch({
                type:PAY,
                payload: cursos.data
            })

        } 
    }

    const selectedCurso = async curso => {
        dispatch({
            type:SELECTED,
            payload: curso
        })
    }

    const cargandoCurso = async() => {
        dispatch({
            type:CARGANDO,
            payload:true
        })
    }

    const saveBlob = (leccion, blob) => {

        dispatch({
            type:SAVEBLOB,
            payload:{leccion:leccion, blob:blob}
        })
    }

    const resetBlob = () => {

        dispatch({
            type:RESETBLOB,
        })
    }


    return (
        <CursoContext.Provider
            value={{
                estado,
                getAllCursos,
                getPayCursos,
                selectedCurso,
                cargandoCurso,
                saveBlob,
                resetBlob
            }}
        > 
            {props.children}
        </CursoContext.Provider>
    )
}

export default CursoState;