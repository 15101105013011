import React from 'react';
import './Cargando.scss';

const Cargando = () => {
    return (
        <div className="wrapper-cargando">
            <h3>Registrándose...</h3>
            <i className="icon-spinner"></i>
        </div>
    );
}
 
export default Cargando;