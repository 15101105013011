import React from 'react';
import { NavLink } from 'react-router-dom';

const ErrorPago = () => {
    return (
        <div className="error-container">
            <h4>Ha habido un error en el proceso de pago. El pago no se ha procesado, y el importe no se ha descontado de su cuenta. Vuelva a intentarlo en unos minutos</h4>
            <NavLink to="/" className="back-to-home">Volver al inicio</NavLink>
        </div>
    );
}
 
export default ErrorPago;