import React, { useEffect, useContext, useState } from 'react';
import RegistroContext from '../../context/registroContext';
import CursoContext from '../../context/cursoContext';
import Curso from './Curso';
import ListaCurso from './ListaCurso';
import { Fragment } from 'react';
import { useHistory } from 'react-router';

const CursosPage = () => {
    const registroContext = useContext(RegistroContext);
    const cursoContext = useContext(CursoContext);
    const { estado, getPayCursos } = cursoContext;
    const history = useHistory();

    useEffect(async () => {
        if(!estado.cursoCargado) {
            try {
                await getPayCursos();
            }
            catch(error) {
                history.push('/');
            }
        }

    }, [estado]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="cursos-wrapper">
            <div className={`listado ${!estado.cursoCargado ? 'd-none': ''}`} >
                <h2>Lista de cursos disponibles</h2>
                {!estado.cursoCargado ? null : estado.cursos.map((curso, i) => {
                    return (
                        <Fragment>
                            <ListaCurso
                                curso={curso.titulo}
                                leccion={curso.leccion}
                                key= {i}
                            />
                        </Fragment>
                    )
                })}
            </div>
            <div className="video-wrapper">
            {!estado.cursoCargado ? <i className="icon-spinner"></i> : estado.cursos.map((curso, i) => {
                    return (
                        <Fragment>
                            <Curso
                                curso={curso}
                                key={i}
                            />
                        </Fragment>
                    )
            })}
            </div>
        </div>
    );
}

export default CursosPage;