import React, { Fragment, useContext, useEffect, useState } from 'react';
import './cursos.scss';
import CursoItem from './CursoItem';
import RegistroContext from '../../context/registroContext';
import CursoContext from '../../context/cursoContext';
import styled from 'styled-components';
import Text from '../home/Text';
import PrecioFinal from './PrecioFinal';
import Carrusel from '../home/Carrusel';
import FAQ from './FAQ';

const WrapperTextos = styled.div`
  padding:2rem;
  display:flex;
  flex-direction:row;

  &:nth-child(odd) {
    flex-direction:row-reverse;

    @media(max-width:768px) {
    flex-direction:column !important;
    }
  }
  
  @media(max-width:768px) {
    flex-direction:column;
  }

  h4 {
    font-size:27px;
  }

  .imagen {
    overflow:hidden;
    margin: 0 1rem;

    img {
      max-width:700px;

      &.ninia {
        position: relative;
        left: -20px;
      }

      @media(max-width:414px) {
        max-width: 400px;
      }
    }
  }

  .texto {
    padding:0 2rem;
    width:70%;
    align-self:center;

    @media(max-width:768px) {
      width: 95%;
      margin-top: 2rem;
    }

    @media(max-width:414px) {
      width:100%;
      margin:1rem 0;
      padding:0;
    }
  }
`

const WrapperContenido = styled.div`
  padding:3rem 2rem 5rem 2rem;
  border:2px solid #0A2239;
  border-radius:10px;

  @media(max-width:768px) {
    padding:1rem;
    margin: 1rem;
  }

`

const Cursos = () => {

  const registroContext = useContext(RegistroContext);
  const cursoContext = useContext(CursoContext);
  const imagenes = ["images/primera-leccion.jpg", "images/persona-sorda-lenguaje.jpg", "hero.jpg"]
  const { state } = registroContext;
  const { getAllCursos, estado } = cursoContext;
  const { cursos } = estado;

  useEffect(async () => {
    if (!estado.cursoCargado) {
      await getAllCursos();
    }
  }, [estado.autenticado]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  const playVideo = () => {
    const videoEle = document.querySelector('#presentacion');
    videoEle.setAttribute('controls', true);
    videoEle.classList.add("show");
    videoEle.textTracks[0].mode = 'showing';
    videoEle.play();
  }

  const pauseVideo = () => {
    const videoEle = document.querySelector('#presentacion');
    videoEle.removeAttribute('controls');
    videoEle.classList.remove('show');
    videoEle.currentTime = 0;

  }

  const lista = ["Aprende y practica la lectura labial (y todo lo que incluye) desde la comodidad de tu hogar y a tu propio ritmo.", "Siéntete mejor a la hora de comunicarte en diferentes ambientes aprendiendo e identificando los diferentes movimientos de la boca.", "Siéntete más conectado en las conversaciones y el mundo que te rodea. Las lecciones de los vídeos te ayudarán a empezar y a construir tu habilidad labio lectora."]

  return (
    <div className="container-cursos">
      <h1>LeeMisLabios</h1>
      <div className="wrapper-video">
        <div className="texto">
          <h2>Bienvenido a LeeMisLabios</h2>
          <p>Estas a un paso de hacer que tu comunicación con tus familiares, amigos, compañeros de trabajo, mejore</p>
          <p>¿Quieres saber más?</p>
          <button onClick={playVideo}>Reproducir vídeo</button>
        </div>
        <video id="presentacion" width="100%" poster="images/poster.jpg" onPause={pauseVideo}>
          <source src='videos/leemislabios-introduccion.mp4' ></source>
          <track src="videos/leemislabios-introduccion.vtt"></track>
        </video>
      </div>

      <WrapperTextos>
      <div className="imagen">
          <img src="images/como-ponerse-o-quitarse-los-audífonos.jpg"/>
        </div>
        <div className="texto">
          <h4>Para comunicarnos lo mejor posible <span className="destacado">no basta con un resto auditivo</span></h4>
          <p>
            Si has notado que respondes cosas diferentes a lo que te preguntan, sí sonríes cuando no has entendido algo y evitas conversaciones para que no te pasen estas cosas, entonces necesitas aprender lectura labial.</p>
            <p>La mayoría de los hipoacúsicos o personas con deficiencia auditiva, se aíslan y se sienten inseguros a la hora de relacionarse. Piensan que el audífono les ayudará en su día a día, que entenderán todo, pero no es así.</p>
            <p>El audífono puede ser de gran ayuda, pero la lectura labial te complementará a ese resto auditivo con o sin audífono.
        </p>
        </div>
      </WrapperTextos>
      <WrapperTextos>
        <div className="imagen">
          <img className="ninia" src="images/lectura-labia-ninia.jpg"/>
        </div>
        <div className="texto">
          <h4>La lectura labial ha ayudado a miles de personas a <span className="destacado">mejorar su comunicación con los demás</span></h4>
          <p>
            Cada día, muchos de mis alumnos se sienten inseguros, desplazados, desmotivados y con miedo a reunirse con amigos, familiares o compañeros de trabajo y que no se enteren de lo que dicen.</p>
            <p>Cada vez salen menos y se aíslan más por temor a quedar en ridículo y el miedo a qué pensarán de ellos.Quizás te sientas identificado con estos sentimientos.</p>
            <p>¿Tú también lo pasas mal cada vez que sales?¿Te sientes mal cuando te confundes y se ríen? ¿Piensas que no te comprenden?</p>
            <p>Sin embargo, hay personas que se comunican sin ningún tipo de problemas aun no escuchando lo que les dicen.¿Quieres ser como ellos?</p>
            <p>Imagínate cómo cambiaría tu vida si después de este curso…</p>
        </div>
      </WrapperTextos>
      <h3>¿De qué se compone el curso?</h3>
      <WrapperContenido>
          <div className="wrapper-leccion">
            {!estado.cursoCargado ? <h3>Estamos cargando la lista de lecciones</h3> : cursos.map((element, index) => {
              return <CursoItem
                titulo={element.titulo}
                leccion={element.leccion}
                nivel={index}
                imagenes={imagenes[index]}
                explicacion={element.explicacion}
                key={index}
              />
            })}
          </div>
          <div className="extra">
            <div className="wrapper-extra">
                <h4>BONUS</h4>
                <p>Además de todos estos vídeos te daré estos <strong>bonus descargables</strong></p>
                <ul>
                  <li><i className="icon-plus mr-3"></i>Bonus de consejos útiles para empezar</li>
                  <li><i className="icon-plus mr-3"></i>Bonus de cómo comunicarte si tienes pérdida auditiva</li>
                  <li><i className="icon-plus mr-3"></i>Bonus de cómo comunicarte con una persona con pérdida auditiva.</li>
                </ul>
              </div>
              <div className="wrapper-extra">
                <p>Y sí eso aun te parece poco, <strong>también podrás descargar:</strong></p>
                <ul>
                  <li><i className="icon-plus mr-3"></i>Qué es la lectura labial</li>
                  <li><i className="icon-plus mr-3"></i>Qué es el triángulo comunicativo</li>
                </ul>
              </div>
          </div>
      </WrapperContenido>
      <PrecioFinal/>
      <Text
          titular="¿Cuáles son los beneficios?"
          subtitulo="Este curso incluye un total de 27 videos y 5 bonus extra que te enseñarán la técnica de la lectura labial y mucho más."
          icono="icon-check"
          lista={lista}
      />
      <h3>TESTIMONIOS</h3>
          <Carrusel
            cursos={true}
          />
          <FAQ/>
    </div>


  );
}

export default Cursos;