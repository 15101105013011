import React from 'react';
import './text.scss';
import styled from '@emotion/styled';

const Text = ({display, titular, subtitulo, lista, icono}) => {

    const WrapperText = styled.section`
        display: flex;
        align-content: center;
        padding:2rem;
        margin:3rem auto;
        background:#0A2239;
        justify-content:space-around;
        border-radius:10px;

        @media(max-width:414px) {
            flex-direction:column;
            margin:1rem;
        }

        &.bloque {
            display:block;
            margin:unset;
            padding:5rem;
            background:lightblue;
            width:40%;
            margin:2rem;
            border-radius:27px;

            &:nth-child(1) {
                background:#0A2239;
                
                li, i, h2 {
                    color:white;
                }
            }
        }

        .wrapper-title {
            max-width: 400px;
            align-self: center;
            margin-right:1rem;


        h2 {
            font-weight: 800;
            text-align: left;
            color:white;
        }

        p {
            font-size: 1rem;
            color:white;
        }
    }

    .wrapper-list {
        padding-left: 1rem;
        max-width:500px;

        ul {
            padding: 0;
            margin:0;
            list-style: none;

            li {
                font-size: 1rem;
                margin: 1rem 0;
                color:white;
                i {
                    font-size: 1.5rem;
                    color:#00B1AD;
                }
            }
        }
    }  
    
    `

    return (  
        <WrapperText className={display}>
            <div className="wrapper-title">
                <h2>{titular}</h2>
                <p>{subtitulo}</p>
            </div>
            <div className="wrapper-list">
                <ul>
                {lista.map((li, i)=> {
                    return <li key={i}><i className={`${icono} mr-3`}></i>{li}</li>
                })}
                </ul>
            </div>
        </WrapperText>
    );
}
 
export default Text;