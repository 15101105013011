import React, { Component, useState } from 'react';
import Hero from '../components/home/Hero';
import Blocks from '../components/home/Blocks';
import Carrusel from '../components/home/Carrusel';
import GiroCard from '../components/home/GiroCard';
import Form from '../components/home/Form';
import TextContact from './Contacto/TextContact';
import BlockContact from './Contacto/BlockContact';

const Home = () => {

    const imagenCollection = ['images/hero.jpg', 'images/hero2.jpg']

    // const frases = ['Aprende y practica la lectura labial (y todo lo que incluye) desde la comodidad de tu hogar y a tu propio ritmo.',
    // 'Siéntete mejor a la hora de comunicarte en diferentes ambientes aprendiendo e identificando los diferentes movimientos de la boca.',
    // 'Siéntete más conectado en las conversaciones y el mundo que te rodea. Las lecciones de los vídeos te ayudarán a empezar y a construir tu habilidad labio lectora.']
    // const frasesNo = ["Ya sabes leer los labios", "No tienes dificultades a la hora de comunicarte", "Piensas que no te puede aportar nada nuevo"];
    // const frasesSi = ["Quieres ganar confianza cuando hables con otras personas", "Quieres seguir una conversación sin perderte a cada rato", "Estás dispuesto a salir de tu zona de confort y aprender herramientas nuevas para tu día a día."];

    const titulosAyuda = [{"titulo": "Personas con pérdida auditiva sin audífonos", "texto":"Cada vez te cuesta entender más a las personas. Estas pensando en si ponerte audífono o quizás lo hayas probado, pero no te termina de ayudar", "imagen":"images/oyente.png"}, { "titulo":"Personas con pérdida auditiva con audífonos", "texto":"Eres portador de audífonos, pero sientes que te amplifica más el ruido y no llegas a discriminar del todo la palabra", "imagen":"images/audifono.png"}, {"titulo":"Personas con implante coclear", "texto":"Eres portador de implante coclear y quieres mejorar tu discriminación auditiva", "imagen":"images/coclear.png"}];

    const titulosCard = [{"titulo": "Servicio de rehabilitación auditiva", "texto":"Servicios de rehabilitación auditiva tanto para adultos como para niños. Centrado en: Detección, Discriminación, Identificación y Reconocimiento"}, { "titulo":"Servicio de lectura labial", "texto":"Si quieres profundizar más en un área especifica con la lectura labial, este servicio es para ti."}, {"titulo":"Terapia vocal", "texto":"Servicio de modulación de la voz para mejorar el ritmo, el tono y conseguir una mayor fluidez verbal."}, {"titulo":"Informe peritaje logopédico", "texto":"Servicio de redacción de informes para el peritaje logopédico especializado en voz y audición."}]


    return ( 
        <main className="contenido-principal">
            <Hero 
                image = {imagenCollection}
            />
            <div className="container-blocks">
                <h3>¿A QUIÉN AYUDO?</h3>
                {titulosAyuda.map(item => {
                    return (<Blocks
                        titulo={item.titulo}
                        texto={item.texto}
                        imagen={item.imagen}
                    />)
                })}
            </div>
            <div className="container-cards">
                <h3>¿QUÉ PUEDO OFRECERTE?</h3>
                {titulosCard.map(card => {
                    return <GiroCard
                        titulo={card.titulo}
                        texto={card.texto}
                    />
                })}
            </div>
            <h3>CONTACTO</h3>
            <BlockContact/>
            <h3>QUIÉNES YA ME CONOCEN...</h3>
            <Carrusel
                cursos={false}
            />
        </main>
    );
}
 
export default Home;