import React, { useEffect, useContext } from 'react';
import './sobremi.scss';
import RegistroContext from '../../context/registroContext';
import { Fragment } from 'react';

const SobreMi = () => {
    const registroContext = useContext(RegistroContext);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <Fragment>
            <h1>Sobre mi</h1>
            <div className="wrapper-about sobremi-wrapper d-flex">
                <div className="foto-about">
                    <img src="images/marta-olmos-logopeda.png" />
                </div>
                <div>
                    <div className="text-about">
                        <div className="formacion-wrapper">
                            <p><span className="destacado">¡HOLA, SOY MARTA OLMOS!</span> Soy una de esas personas que le apasiona lo que hace y por eso me gusta estar al día de los últimos recursos y avances para ofrecer lo mejor a cada persona.</p>
                            <p>He trabajado en el sector público y privado en diversas áreas de la logopedia: voz, comunicación, parálisis facial, Parkinson, Esclerosis Múltiple, deficiencias neurodegenerativas y un largo etcétera.</p>
                            <p>Pero sobre todo he ayudado a personas con deficiencia auditiva como tú a mejorar su comunicación desde que acabé la carrera allá por el año 2016. Aunque en el año 2011 ya empecé a tener relación en esta área.</p>
                            <p>Después de años trabajando en el área de la audición en el mundo de la logopedia y más concretamente a enseñar lectura labial y rehabilitación auditiva a personas hipoacúsicas o con implante coclear, he decidido acercar el curso que imparto y mis servicios a todo aquel que lo necesite.</p>
                            <div className="card-formacion">
                                <h4><i className="icon-book"></i>Formación</h4>
                                <ul>
                                    <li>Diplomada en logopedia por la Universidad Complutense de Madrid. <span>NºCol.28/0751</span></li>
                                    <li><span>Máster de especialista y experto en audición</span> por la Universidad de Salamanca.</li>
                                    <li><span>Intérprete de la Lengua de Signos Española</span> por la institución Trabasse.</li>
                                    <li><span>Formación continuada</span> con asistencia a Congresos, Seminarios y Cursos centrados en la audición, comunicación, voz y diversas patologías del habla y del lenguaje.</li>
                                    <li>Creadora del curso <span>LEEMISLABIOS</span>.</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default SobreMi;