import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './footer.scss';

const Footer = ({fecha}) => {
    return(
        <footer>
            <div className="footerContainer">
                <div className="copy-wrapper">
                    <p>martaolmos.com &copy; {fecha}</p>
                </div>
                <div className="stripe">
                    <img src="images/stripe.png"/>
                    <p>Está web usa el sistema de pago de Stripe. Para saber más sobre el método de pago, pulsa <a target="_blank" href="https://stripe.com">aquí</a></p>
                    <p>Si tienes alguna duda o problema a la hora de realizar el pago puedes ponerte en contacto con nosotros en el siguiente email: <a href="mailto:leemislabiosinfo@gmail.com">leemislabiosinfo@gmail.com</a></p>
                </div>
                <div className="menu-footer">
                    <ul>
                        <li><NavLink to="/politica-privacidad">Política de Privacidad</NavLink></li>
                        <li><NavLink to="/politica-cookies">Política de Cookies</NavLink></li>
                        <li><NavLink to="/politica-devolucion">Política de devolución</NavLink></li>
                        <li><NavLink to="/leemislabios">LeeMisLabios</NavLink></li>
                        <li><NavLink to="/sobremi">Sobre mi</NavLink></li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;