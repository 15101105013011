import React, { Fragment, useContext, useEffect, useState } from 'react';
import Video from './Video';
import styled from '@emotion/styled';
import CursoContext from '../../context/cursoContext';

const Curso = ({ curso }) => {

    const cursoContext = useContext(CursoContext);

    const {estado} = cursoContext;

    const [open, setOpen] = useState(false);
    // const handleClick = () => {
    //     setOpen(!open);
    // }

    const Acordeon = styled.div`
        display:none;
        padding:1rem;
        cursor: pointer;

        &.open {
            display:flex;
        }
    
    `

    let { titulo, url, subtitulo, descripcion, leccion } = curso;

    return (
        <Fragment>
            <div className="video">
                {url.map((video, index) => {
                    if(leccion[index] === estado.cursoSeleccionado) {
                        return <Video
                        url={video}
                        leccion={leccion[index]}
                        subtitulo={subtitulo[index]}
                        titulo={titulo}
                        key={index}
                    />
                    }    
                })}
            </div>
        </Fragment>
    );
}

export default Curso;
